import React from 'react';
import { AppWrapper } from '../../components/AppWrapper';
import styled from 'styled-components';
import { GRAY, LIGHT_GRAY, PURPLE, REACT_GRAY, WHITE } from '../../constants';
import { hexToRGB } from '../../utils';
import { MadeWithLoveAtPhantom } from '../../components/MadeWithLoveAtPhantom';

const PASS = 'PASS ✅';
const FAIL = 'FAIL ❌';

const Main = styled.main`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  background-color: ${REACT_GRAY};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100vh;

  > * {
    margin-bottom: 10px;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

const IconContainer = styled.a.attrs({
  href: '/provider-doctor',
})`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 5px;

  &:focus-visible {
    outline: 2px solid ${hexToRGB(GRAY, 0.5)};
    border-radius: 6px;
  }
`;

const SectionHeader = styled.h4`
  color: ${GRAY};
  margin: 16px 0 8px;
`;

const Code = styled.span`
  background-color: ${LIGHT_GRAY};
  border-radius: 6px;
  padding: 0.1em 0.2em;
  font-family:
    ui-monospace,
    SFMono-Regular,
    SF Mono,
    Menlo,
    Consolas,
    Liberation Mono,
    monospace;
  font-size: 85%;
  color: ${PURPLE};
`;

const App = () => {
  // SOL
  const windowSolana = !!(window as any)?.solana;
  const windowPhantomSolana = !!(window as any)?.phantom?.solana;
  const solDisconnect = typeof window?.phantom?.solana?.disconnect === 'function';
  const solOn = typeof window?.phantom?.solana?.on === 'function';
  const solSignAndSendTransaction = typeof window?.phantom?.solana?.signAndSendTransaction === 'function';
  const solSignAndSendAllTransactions = typeof window?.phantom?.solana?.signAndSendAllTransactions === 'function';
  const solSignTransaction = typeof window?.phantom?.solana?.signTransaction === 'function';
  const solSignAllTransactions = typeof window?.phantom?.solana?.signAllTransactions === 'function';
  const solSignMessage = typeof window?.phantom?.solana?.signMessage === 'function';

  // EVM
  const windowEthereum = !!(window as any)?.ethereum;
  const windowPhantomEthereum = !!(window as any)?.phantom?.ethereum;
  const evmRequest = typeof window?.phantom?.ethereum?.request === 'function';
  const evmIsConnected = typeof window?.phantom?.ethereum?.isConnected === 'function';
  const evmOn = typeof window?.phantom?.ethereum?.on === 'function';

  // BTC
  const windowPhantomBitcoin = !!(window as any)?.phantom?.bitcoin;
  const btcRequestAccounts = typeof window?.phantom?.bitcoin?.requestAccounts === 'function';
  const btcOn = typeof window?.phantom?.bitcoin?.on === 'function';
  const btcSignPSBT = typeof window?.phantom?.bitcoin?.signPSBT === 'function';
  const btcSignMessage = typeof window?.phantom?.bitcoin?.signMessage === 'function';

  return (
    <AppWrapper>
      <Main>
        <Body>
          {/* Icon */}
          <IconContainer>
            <img src="/images/phantom-icon-purple.png" alt="Phantom" width="75" />
          </IconContainer>

          <SectionHeader>SOL provider</SectionHeader>
          <ul>
            <li aria-label={`SOL window.solana ${windowSolana ? 'pass' : 'fail'}`}>
              SOL provider at <Code>window.solana</Code> - {windowSolana ? PASS : FAIL}
            </li>
            <li aria-label={`SOL window.phantom.solana ${windowPhantomSolana ? 'pass' : 'fail'}`}>
              SOL provider at <Code>window.phantom.solana</Code> - {windowPhantomSolana ? PASS : FAIL}
            </li>
            <li aria-label={`SOL disconnect ${solDisconnect ? 'pass' : 'fail'}`}>
              SOL <Code>disconnect</Code> method - {solDisconnect ? PASS : FAIL}
            </li>
            <li aria-label={`SOL on ${solOn ? 'pass' : 'fail'}`}>
              SOL event listener <Code>on</Code> method - {solOn ? PASS : FAIL}
            </li>
            <li aria-label={`SOL signAndSendTransaction ${solSignAndSendTransaction ? 'pass' : 'fail'}`}>
              SOL <Code>signAndSendTransaction</Code> method - {solSignAndSendTransaction ? PASS : FAIL}
            </li>
            <li aria-label={`SOL signAndSendAllTransactions ${solSignAndSendAllTransactions ? 'pass' : 'fail'}`}>
              SOL <Code>signAndSendAllTransactions</Code> method - {solSignAndSendAllTransactions ? PASS : FAIL}
            </li>
            <li aria-label={`SOL signTransaction ${solSignTransaction ? 'pass' : 'fail'}`}>
              SOL <Code>signTransaction</Code> method - {solSignTransaction ? PASS : FAIL}
            </li>
            <li aria-label={`SOL signAllTransactions ${solSignAllTransactions ? 'pass' : 'fail'}`}>
              SOL <Code>signAllTransactions</Code> method - {solSignAllTransactions ? PASS : FAIL}
            </li>
            <li aria-label={`SOL signMessage ${solSignMessage ? 'pass' : 'fail'}`}>
              SOL <Code>signMessage</Code> method - {solSignMessage ? PASS : FAIL}
            </li>
          </ul>
          <SectionHeader>EVM provider</SectionHeader>
          <ul>
            <li aria-label={`EVM window.etheremum ${windowEthereum ? 'pass' : 'fail'}`}>
              EVM provider at <Code>window.ethereum</Code> - {windowEthereum ? PASS : FAIL}
            </li>
            <li aria-label={`EVM window.phantom.ethereum ${windowPhantomEthereum ? 'pass' : 'fail'}`}>
              EVM provider at <Code>window.phantom.ethereum</Code> - {windowPhantomEthereum ? PASS : FAIL}
            </li>
            <li aria-label={`EVM request ${evmRequest ? 'pass' : 'fail'}`}>
              EVM <Code>request</Code> method - {evmRequest ? PASS : FAIL}
            </li>
            <li aria-label={`EVM isConnected ${evmIsConnected ? 'pass' : 'fail'}`}>
              EVM <Code>isConnected</Code> method - {evmIsConnected ? PASS : FAIL}
            </li>
            <li aria-label={`EVM on ${evmOn ? 'pass' : 'fail'}`}>
              EVM event listener <Code>on</Code> method - {evmOn ? PASS : FAIL}
            </li>
          </ul>
          <SectionHeader>BTC provider</SectionHeader>
          <ul>
            <li aria-label={`BTC window.phantom.bitcoin ${windowPhantomBitcoin ? 'pass' : 'fail'}`}>
              BTC provider at <Code>window.phantom.bitcoin</Code> - {windowPhantomBitcoin ? PASS : FAIL}
            </li>
            <li aria-label={`BTC requestAccounts ${btcRequestAccounts ? 'pass' : 'fail'}`}>
              BTC <Code>requestAccounts</Code> method - {btcRequestAccounts ? PASS : FAIL}
            </li>
            <li aria-label={`BTC on ${btcOn ? 'pass' : 'fail'}`}>
              BTC event listener <Code>on</Code> method - {btcOn ? PASS : FAIL}
            </li>
            <li aria-label={`BTC signPSBT ${btcSignPSBT ? 'pass' : 'fail'}`}>
              BTC <Code>signPSBT</Code> method - {btcSignPSBT ? PASS : FAIL}
            </li>
            <li aria-label={`BTC signMessage ${btcSignMessage ? 'pass' : 'fail'}`}>
              BTC <Code>signMessage</Code> method - {btcSignMessage ? PASS : FAIL}
            </li>
          </ul>
        </Body>
        <MadeWithLoveAtPhantom />
      </Main>
    </AppWrapper>
  );
};

export default App;
