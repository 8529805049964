import { SolanaSignInInput, SolanaSignInOutput } from '@solana/wallet-standard-features';
import { PublicKey, SendOptions, Transaction, VersionedTransaction } from '@solana/web3.js';
import { providers } from 'ethers';

export type ConnectedMethods = {
  chainIds: string[];
  name: string;
  onClick: (chainId?: string) => any;
};

type SolanaDisplayEncoding = 'utf8' | 'hex';

interface SolanaConnectOpts {
  onlyIfTrusted: boolean;
}

export interface ModifiedSolanaSignInOutput extends SolanaSignInOutput {
  address?: string;
}

export interface PhantomSolanaProvider {
  publicKey: PublicKey | null;
  isConnected: boolean | null;
  signAndSendTransaction: (
    transaction: Transaction | VersionedTransaction,
    opts?: SendOptions
  ) => Promise<{ signature: string; publicKey: PublicKey }>;
  signAndSendAllTransactions: (
    transactions: (Transaction | VersionedTransaction)[],
    opts?: SendOptions
  ) => Promise<{ publicKey: string; signatures: (string | null)[] }>;
  signTransaction: (transaction: Transaction | VersionedTransaction) => Promise<Transaction | VersionedTransaction>;
  signAllTransactions: (
    transactions: (Transaction | VersionedTransaction)[]
  ) => Promise<(Transaction | VersionedTransaction)[]>;
  signMessage: (message: Uint8Array | string, display?: SolanaDisplayEncoding) => Promise<any>;
  signIn: (signInData: SolanaSignInInput) => Promise<SolanaSignInOutput | ModifiedSolanaSignInOutput>;
  connect: (opts?: Partial<SolanaConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, handler: (args: any) => void) => void;
  request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}

export interface PhantomEthereumProvider {
  isMetaMask?: boolean; // will be removed after beta
  isPhantom: boolean;
  on: (event: EthereumEvent, handler: (args: any) => void) => void;
  request: (args: { method: EthereumRequestMethod; params?: unknown[] | object }) => Promise<unknown>;
  _metamask: {
    isUnlocked: boolean;
  };
}

export interface PhantomInjectedProvider {
  ethereum: PhantomEthereumProvider;
  solana: PhantomSolanaProvider;
}

export type PhantomProviderType = 'solana' | 'ethereum';

export type Web3Provider = providers.Web3Provider;

export type SolanaEvent = 'connect' | 'disconnect' | 'accountChanged';

export type EthereumEvent = 'connect' | 'disconnect' | 'accountsChanged' | 'chainChanged';

export type PhantomEvent = SolanaEvent | EthereumEvent;

export type SolanaRequestMethod =
  | 'connect'
  | 'disconnect'
  | 'signAndSendTransaction'
  | 'signAndSendTransactionV0'
  | 'signAndSendAllTransactions'
  | 'signAndSendTransactionV0WithLookupTable'
  | 'signTransaction'
  | 'signAllTransactions'
  | 'signMessage'
  | 'signIn';

/**
 * A subset of Phantom's supported JSON RPC methods
 * Phantom accepts most JSON RPC requests that are expected of wallets
 * For more information, please see: https://ethereum.org/en/developers/docs/apis/json-rpc/
 */
export type EthereumRequestMethod =
  | 'eth_getTransactionReceipt'
  | 'eth_sendTransaction'
  | 'eth_requestAccounts'
  | 'personal_sign'
  | 'eth_accounts'
  | 'eth_chainId'
  | 'wallet_switchEthereumChain'
  | 'eth_signTypedData_v4'
  | 'eth_signTypedData_v3'
  | 'eth_signTypedData_v1'
  | 'eth_signTypedData'
  | 'net_version';

export type PhantomRequestMethod = SolanaRequestMethod | EthereumRequestMethod;

export type Status = 'success' | 'warning' | 'error' | 'info';

export interface TLog {
  status: Status;
  providerType?: 'solana' | 'ethereum';
  method?: PhantomRequestMethod | 'accountChanged' | 'accountsChanged' | 'chainChanged';
  message: string;
  timestamp?: number;
  confirmation?: { signature: string; link: string };
  messageTwo?: string;
}

export interface LocalStorageParams {
  sharedSecret?: string;
  session?: string;
  phantomWalletPublicKey?: string;
  dappPubkey?: string;
  dappSecretkey?: string;
}

export interface DeeplinkState {
  sharedSecret?: Uint8Array;
  session?: string;
  phantomWalletPublicKey?: PublicKey;
  dappPubkey?: Uint8Array;
  dappSecretkey?: Uint8Array;
}

export enum Platform {
  iOS = 'iOS',
  Android = 'Android',
  Other = 'Other',
}
