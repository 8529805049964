import { Transaction, Connection, PublicKey, Keypair } from '@solana/web3.js';

// Transaction with no instructions and a random fee payer should throw an error
const createMalformedTransferTransaction = async (
  publicKey: PublicKey,
  connection: Connection,
): Promise<Transaction> => {
  const transaction = new Transaction();

  transaction.feePayer = Keypair.generate().publicKey;
  transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

  return transaction;
};

export default createMalformedTransferTransaction;
