/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {
  getProvider,
  sendTransactionPhantomProvider,
  mintNFT,
  mintToken,
  approveERC20,
  revokeERC20,
  approveAllERC721,
  revokeAllERC721,
  lockERC20,
  signPermit2ERC20,
  signTypedDataV1,
  signTypedDataV3,
  signTypedDataV4,
  signEIP2612,
} from '../../utils/evm';

import Sidebar from '../../components/Sidebar';
import Button from '../../components/Button';
import { EVMNetworkSelector } from '../../components/NetworkSelector';
import { ActionButtons } from '../../components/Sidebar/ActionButtons';
import { ConnectedMethods, TLog, Web3Provider } from '../../types';
import { SUPPORTED_CHAINS, SupportedEVMChainIds } from '../../constants/chains';
import { LogsProvider, useLogs } from '../../hooks/useLogs';
import { Logs } from '../../components/Logs';
import { AppWrapper } from '../../components/AppWrapper';
import { TestId } from '../../components/TestId';
import { TransactionResponse } from '@ethersproject/providers';
import { parseEther } from 'viem';
import { ConnectedAs } from '../../components/Sidebar/ConnectedAs';

// =============================================================================
// Constants
// =============================================================================

let accounts = [];
const message = 'To avoid digital dognappers, sign below to authenticate with CryptoCorgis.';
const sleep = (timeInMS) => new Promise((resolve) => setTimeout(resolve, timeInMS));

// =============================================================================
// Typedefs
// =============================================================================

interface Props {
  address: string | null;
  connectedMethods: ConnectedMethods[];
  handleConnect: () => Promise<void>;
  network: SupportedEVMChainIds;
  setNetwork: (network: SupportedEVMChainIds) => void;
  provider: Web3Provider;
  logs: TLog[];
  clearLogs: () => void;
  logsVisibility: boolean;
  toggleLogs: () => void;
}

// =============================================================================
// Hooks
// =============================================================================

/**
 * @DEVELOPERS
 * The fun stuff!
 */
const useProps = (): Props => {
  const [provider, setProvider] = useState<Web3Provider | null>(null);
  const [address, setAddress] = useState('');
  const { logs, createLog, clearLogs, toggleLogs, logsVisibility } = useLogs();
  const [network, setNetwork] = useState<SupportedEVMChainIds>(SupportedEVMChainIds.EthereumMainnet);

  useEffect(() => {
    (async () => {
      // sleep for 100 ms to give time to inject
      await sleep(100);
      setProvider(getProvider());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!provider) return;
      const netVersion = await provider.send('net_version', []);
      setNetwork(`0x${netVersion}` as SupportedEVMChainIds);
    })();
  }, [provider]);

  useEffect(() => {
    if (!provider) return;

    handleConnect();

    window.phantom.ethereum.on('connect', (connectionInfo: { chainId: string }) => {
      createLog({
        status: 'success',
        method: 'connect',
        message: `Connected to chain: ${connectionInfo.chainId}`,
      });
    });

    window.phantom.ethereum.on('disconnect', () => {
      createLog({
        status: 'warning',
        method: 'disconnect',
        message: 'lost connection to the rpc',
      });
    });

    window.phantom.ethereum.on('accountsChanged', (newAccounts: String[]) => {
      if (newAccounts) {
        createLog({
          status: 'info',
          method: 'accountChanged',
          message: `Switched to account: ${newAccounts}`,
        });
        accounts = newAccounts;
        setAddress(accounts[0]);
      } else {
        /**
         * In this case dApps could...
         *
         * 1. Not do anything
         * 2. Only re-connect to the new account if it is trusted
         *
         * ```
         * provider.send('eth_requestAccounts', []).catch((err) => {
         *  // fail silently
         * });
         * ```
         *
         * 3. Always attempt to reconnect
         */

        createLog({
          status: 'info',
          method: 'accountChanged',
          message: 'Attempting to switch accounts.',
        });

        provider.send('eth_requestAccounts', []).catch((error) => {
          createLog({
            status: 'error',
            method: 'accountChanged',
            message: `Failed to re-connect: ${error.message}`,
          });
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, createLog]);

  const waitForTransaction = useCallback(
    async (chainId: string, transaction: TransactionResponse) => {
      try {
        // wait for the transaction to be included in the next block
        const txReceipt = await transaction.wait(1); // 1 is number of blocks to be confirmed before returning the receipt
        createLog({
          status: 'success',
          method: 'eth_sendTransaction',
          message: `TX included: ${JSON.stringify(txReceipt)}`,
          confirmation: {
            signature: `${JSON.stringify(txReceipt)}`,
            link: `${SUPPORTED_CHAINS[chainId].explorer}/tx/${JSON.stringify(txReceipt)}`,
          },
        });
      } catch (error) {
        // log out if the tx didn't get included for some reason
        createLog({
          status: 'error',
          method: 'eth_sendTransaction',
          message: `Failed to include transaction on the chain: ${error.message}`,
        });
      }
    },
    [createLog]
  );

  // Reusable execute transaction for all methods that do a eth_sendTransaction
  const executeTransaction = useCallback(
    async (method: Function) => {
      if (!provider) return;

      try {
        // send the transaction up to the network
        const transaction = await method();
        createLog({
          status: 'info',
          method: 'eth_sendTransaction',
          message: `Sending transaction: ${JSON.stringify(transaction)}`,
        });
        await waitForTransaction('1', transaction);
      } catch (error) {
        createLog({
          status: 'error',
          method: 'eth_sendTransaction',
          message: error.message,
        });
      }
    },
    [provider, createLog, waitForTransaction]
  );

  // Reusable execute signMessage for all methods that do a eth_sign
  const executeSignMessage = useCallback(
    async (
      method: Function,
      methodName: 'eth_signTypedData_v4' | 'eth_signTypedData_v3' | 'eth_signTypedData' | 'signMessage'
    ) => {
      if (!provider) return;

      try {
        const signature = await method();
        createLog({
          status: 'success',
          method: methodName,
          message: `Message signed: ${signature}`,
        });
        return signature;
      } catch (error) {
        createLog({
          status: 'error',
          method: methodName,
          message: error.message,
        });
      }
    },
    [provider, createLog]
  );

  /** eth_sendTransaction */
  const handleEthSendTransaction = (chainId, address) =>
    executeTransaction(() => sendTransactionPhantomProvider(provider, address));

  /** Mint token */
  const handleMintToken = (chainId) => executeTransaction(() => mintToken(chainId, provider));

  /** Mint nft */
  const handleMintNFT = (chainId) => executeTransaction(() => mintNFT(chainId, provider));

  /** Approve ERC20 token */
  const handleApproveERC20Token = (chainId) => executeTransaction(() => approveERC20(chainId, provider));

  /** Revoke ERC20 token */
  const handleRevokeERC20Token = (chainId) => executeTransaction(() => revokeERC20(chainId, provider));

  /** Approve all NFT token */
  const handleApproveAllNFT = (chainId) => executeTransaction(() => approveAllERC721(chainId, provider));

  /** Revoke all NFT token */
  const handleRevokeAllNFT = (chainId) => executeTransaction(() => revokeAllERC721(chainId, provider));

  /** Lock ERC20 token */
  const handleLockERC20Token = (chainId) => executeTransaction(() => lockERC20(chainId, provider, parseEther('1')));

  /** Signs a permit 2 ERC20 approve */
  const handlePermit2ERC20Token = (chainId) =>
    executeSignMessage(
      () => signPermit2ERC20(chainId, provider, parseEther('1000000000000000000000')),
      'eth_signTypedData_v4'
    );

  /** SignMessage */
  const handleSignMessage = () =>
    executeSignMessage(() => {
      const signer = provider.getSigner();
      return signer.signMessage(message);
    }, 'signMessage');

  /** SignTypedDataV1 */
  const handleSignTypedDataV1 = (chainId) =>
    executeSignMessage(() => signTypedDataV1(chainId, provider, 'Hello, Phantom!'), 'eth_signTypedData');

  /** SignTypedDataV3 */
  const handleSignTypedDataV3 = (chainId) =>
    executeSignMessage(() => signTypedDataV3(chainId, provider), 'eth_signTypedData_v3');

  /** SignTypedDataV4 */
  const handleSignTypedDataV4 = (chainId) =>
    executeSignMessage(() => signTypedDataV4(chainId, provider), 'eth_signTypedData_v4');

  /** SignEIP2612 */
  const handleSignEIP2612 = (chainId) =>
    executeSignMessage(() => signEIP2612(chainId, provider), 'eth_signTypedData_v4');

  /** Connect */
  const handleConnect = useCallback(async () => {
    if (!provider) return;

    try {
      accounts = await provider.send('eth_requestAccounts', []);
      createLog({
        status: 'success',
        method: 'connect',
        message: `connected to account: ${accounts[0]}`,
      });
    } catch (error) {
      createLog({
        status: 'error',
        method: 'connect',
        message: error.message,
      });
    }
  }, [provider, createLog]);

  /** Disconnect */
  const handleDisconnect = useCallback(async () => {
    createLog({
      status: 'warning',
      method: 'disconnect',
      message: '👋',
    });
    accounts = [];
    setAddress('');
  }, [createLog]);

  const connectedMethods = useMemo(() => {
    return [
      {
        name: 'Send Transaction',
        chainIds: [
          SupportedEVMChainIds.EthereumMainnet,
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: (chainId: string) => handleEthSendTransaction(chainId, address),
      },
      {
        name: 'Send Transaction to 0x3Ed5fFfe493D4066191D7B7E76784A33deFd0018',
        chainIds: [
          SupportedEVMChainIds.EthereumMainnet,
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,

          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        // Send to this address
        onClick: (chainId: string) => handleEthSendTransaction(chainId, '0x3Ed5fFfe493D4066191D7B7E76784A33deFd0018'),
      },

      {
        name: 'Mint Tokens (free)',
        chainIds: [
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: (chainId) => handleMintToken(chainId),
      },
      {
        name: 'Approve ERC20 token',
        chainIds: [
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: (chainId) => handleApproveERC20Token(chainId),
      },

      {
        name: 'Revoke ERC20 token',
        chainIds: [
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: (chainId) => handleRevokeERC20Token(chainId),
      },

      {
        name: 'Lock ERC20 token',
        chainIds: [
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: (chainId) => handleLockERC20Token(chainId),
      },

      {
        name: 'Approve 2 ERC20 token (permit2)',
        chainIds: [
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: (chainId) => handlePermit2ERC20Token(chainId),
      },

      {
        name: 'Approve a lot ERC20 token (permit2)',
        chainIds: [
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: (chainId) => handlePermit2ERC20Token(chainId),
      },
      {
        name: 'Mint NFT (free)',
        chainIds: [
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: (chainId) => handleMintNFT(chainId),
      },

      {
        name: 'Approve all NFT token',
        chainIds: [
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: (chainId) => handleApproveAllNFT(chainId),
      },
      {
        name: 'Revoke all NFT token',
        chainIds: [
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: (chainId) => handleRevokeAllNFT(chainId),
      },

      {
        name: 'Sign Message',
        chainIds: [
          SupportedEVMChainIds.EthereumMainnet,
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: handleSignMessage,
      },
      {
        name: 'Sign Typed Data (v1)',
        chainIds: [
          SupportedEVMChainIds.EthereumMainnet,
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: handleSignTypedDataV1,
      },
      {
        name: 'Sign Typed Data (v3)',
        chainIds: [
          SupportedEVMChainIds.EthereumMainnet,
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: handleSignTypedDataV3,
      },
      {
        name: 'Sign Typed Data (v4)',
        chainIds: [
          SupportedEVMChainIds.EthereumMainnet,
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: handleSignTypedDataV4,
      },
      {
        name: 'Sign EIP2612',
        chainIds: [
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: handleSignEIP2612,
      },
      {
        name: 'Reconnect',
        chainIds: [
          SupportedEVMChainIds.EthereumMainnet,
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: handleConnect,
      },
      {
        name: 'Disconnect',
        chainIds: [
          SupportedEVMChainIds.EthereumMainnet,
          SupportedEVMChainIds.EthereumSepolia,
          SupportedEVMChainIds.PolygonMainnet,
          SupportedEVMChainIds.PolygonAmoy,
          SupportedEVMChainIds.BaseMainnet,
          SupportedEVMChainIds.BaseSepolia,
        ],
        onClick: handleDisconnect,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleConnect, handleEthSendTransaction, handleSignMessage]);

  return {
    address: accounts[0],
    connectedMethods,
    handleConnect,
    network,
    setNetwork,
    provider,
    logs,
    clearLogs,
    logsVisibility,
    toggleLogs,
  };
};

// =============================================================================
// Main Component
// =============================================================================

const App = () => {
  const { address, connectedMethods, handleConnect, network, setNetwork, logs, clearLogs, logsVisibility, toggleLogs } =
    useProps();

  return (
    <AppWrapper>
      <Sidebar
        logsVisibility={logsVisibility}
        toggleLogs={toggleLogs}
        topSection={EVMNetworkSelector({ network, setNetwork })}
        activePath="/eth-sandbox"
      >
        {address && (
          <ConnectedAs
            addresses={{
              evm: address,
              solana: null,
            }}
          />
        )}
        {!address && (
          <div>
            <Button onClick={handleConnect} data-testid="connect">
              Connect to Phantom
            </Button>
            <TestId id="connect" />
          </div>
        )}

        <ActionButtons
          connectedMethods={connectedMethods
            .filter((method) => method.chainIds.includes(network))
            .map((method) => {
              return {
                ...method,
                chainIds: [network],
              };
            })}
          connected={!!address}
        />
      </Sidebar>
      {logsVisibility && <Logs connected={!!address} logs={logs} clearLogs={clearLogs} />}
    </AppWrapper>
  );
};

const AppWithProviders = () => {
  return (
    <LogsProvider>
      <App />
    </LogsProvider>
  );
};

export default AppWithProviders;
