import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SolSandbox } from './sandboxes/sol-sandbox';
import { BlinksSandbox } from './sandboxes/blinks-sandbox';
import { SolMalformedRequestsSandbox } from './sandboxes/sol-malformed-requests-sandbox';
import { EthSandbox } from './sandboxes/eth-sandbox';
import { MultiChainSandbox } from './sandboxes/multi-chain-sandbox';
import { SolAdapterSandbox } from './sandboxes/adapter-sandbox';
import { DeeplinksSandbox } from './sandboxes/deeplinks-sandbox';
import { Web3ReactV6Sandbox } from './sandboxes/web3-react-v6-sandbox';
import { Web3ReactV8Sandbox } from './sandboxes/web3-react-v8-sandbox';
import { RainbowKitSandbox } from './sandboxes/rainbowkit-sandbox';
import { WagmiSandbox } from './sandboxes/wagmi-sandbox';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SolAdvancedSandbox } from './sandboxes/sol-advanced-sandbox';
import { ProviderDoctor } from './sandboxes/provider-doctor';

function App() {
  return (
    <Router>
      <ToastContainer theme="dark" />
      <Routes>
        <Route index element={<SolSandbox />} />
        <Route path="/sol-sandbox" element={<SolSandbox />} />
        <Route path="/eth-sandbox" element={<EthSandbox />} />
        <Route path="/multi-chain-sandbox" element={<MultiChainSandbox />} />
        <Route path="/sol-adapter-sandbox" element={<SolAdapterSandbox />} />
        <Route path="/deeplinks-sandbox" element={<DeeplinksSandbox />} />
        <Route path="/web3-react-v6-sandbox" element={<Web3ReactV6Sandbox />} />
        <Route path="/web3-react-v8-sandbox" element={<Web3ReactV8Sandbox />} />
        <Route path="/rainbowkit-sandbox" element={<RainbowKitSandbox />} />
        <Route path="/wagmi-sandbox" element={<WagmiSandbox />} />
        <Route path="/blinks-sandbox" element={<BlinksSandbox />} />
        <Route path="/sol-malformed-requests-sandbox" element={<SolMalformedRequestsSandbox />} />
        <Route path="/sol-advanced-sandbox" element={<SolAdvancedSandbox />} />
        <Route path="/provider-doctor" element={<ProviderDoctor />} />
      </Routes>
    </Router>
  );
}

export default App;
