import type { FC, ReactNode } from 'react';
import React, { createContext } from 'react';
import { useLocalStorage } from './useLocalStorage';

type SetStateAction<S> = React.Dispatch<React.SetStateAction<S>>;

interface PreferencesContextState {
  autoConnect: boolean;
  onlyIfTrusted: boolean;
  logAutoConnectErrors: boolean;
  setAutoConnect: SetStateAction<boolean>;
  setOnlyIfTrusted: SetStateAction<boolean>;
  setLogAutoConnectErrors: SetStateAction<boolean>;
}

const PreferencesContext = createContext<PreferencesContextState>({} as PreferencesContextState);

export const usePreferences = () => {
  const context = React.useContext(PreferencesContext);

  if (!context) {
    throw new Error('onlyIfTrustedContext must be used within a OnlyIfTrustedProvider');
  }

  return context;
};

export const PreferencesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [autoConnect, setAutoConnect] = useLocalStorage('autoConnect', true);
  const [onlyIfTrusted, setOnlyIfTrusted] = useLocalStorage('onlyIfTrusted', true);
  const [logAutoConnectErrors, setLogAutoConnectErrors] = useLocalStorage('logAutoConnect', true);

  return (
    <PreferencesContext.Provider
      value={{ logAutoConnectErrors, setLogAutoConnectErrors, onlyIfTrusted, setOnlyIfTrusted, autoConnect, setAutoConnect }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};
