import { PhantomSolanaProvider } from '../../types';

const signMalformedMessage = async (
  message: string,
  provider?: PhantomSolanaProvider,
): Promise<Uint8Array | string> => {
  try {
    // Phantom would expect a buffer, not a string
    if (provider) return await provider.signMessage(message);
  } catch (error) {
    console.warn(error);
    throw new Error(error.message);
  }
};

export default signMalformedMessage;
